import React from 'react'

import { H2, PMedium, PSmall } from 'src/styles/commons'

import { InterUIAccordion } from '@interco/inter-ui-react-lib'

import { CommonQuestionsContent } from './CommonQuestions.styles'
import { ICommonQuestions } from './types'

export const CommonQuestions: React.FC = () => {
  const commonQuestions: ICommonQuestions[] = [
    {
      id: 0,
      question: 'O que é o Cartão Mais Protegido Empresarial?',
      firstAnswer:
        'O Cartão Mais Protegido é um seguro emitido pela Liberty Seguros e vendido pela Plataforma de Proteção do Inter, que protege o beneficiário de prejuízos financeiros causados pela perda, roubo e furto do cartão, bem como de operações de saques e compras por coação ou ameaça.',
    },
    {
      id: 1,
      question: 'O que o Cartão Mais Protegido Empresarial cobre?',
      firstAnswer:
        '1: Cobertura dos prejuízos causados perda, roubo ou furto do seu cartão. Para ter direito ao benefício, as ocorrências deverão ser comunicadas ao seguro em até 48 horas após o evento, através de boletim de ocorrência.',
      secondAnswer:
        '2: Cobertura dos prejuízos causados por compras ou saques em terminais eletrônicos feitos sob coação ou ameaça. O limite para reportar esse tipo de problema é de até 48 horas após o ocorrido e também é necessário comprovar a ação por meio de boletim de ocorrência.',
    },
    {
      id: 2,
      question: 'Como funciona a participação nos sorteios mensais?',
      firstAnswer:
        'Cada segurado tem direito a um título de capitalização, também conhecido como número da sorte, composto de cinco algarismos entre 00.001 e 99.999, que vale durante todo o período de vigência do seguro. Para ser contemplado no sorteio o número da sorte deve coincidir, da esquerda para a direita, com as unidades finais dos cinco primeiros prêmios extraídos pela Loteria Federal. Os sorteios ocorrem mensalmente, sempre no último sábado de cada mês. Caso não ocorra extração da Loteria Federal no período, o sorteio será adiado para o mês seguinte sem que haja prejuízo para os participantes.',
    },
    {
      id: 3,
      question: 'Qual o tempo de vigência do Cartão Mais Protegido Empresarial?',
      firstAnswer:
        'O seguro vale por 12 meses, contados após a contratação, desde que sejam feitos os pagamentos de todas as mensalidades do produto. A renovação é feita de forma automática, exceto se houver manifestação contrária por parte do cliente.',
    },
  ]

  return (
    <>
      <H2 marginBottom="0">Perguntas frequentes</H2>
      <CommonQuestionsContent>
        {commonQuestions.map((item) => (
          <InterUIAccordion
            key={item.id}
            id={`questions-${item.id}`}
            data-testid={`questions-${item.id}`}
            header={<PMedium marginBottom="0">{item.question}</PMedium>}
            border="bottom"
          >
            <PSmall scale={400} marginBottom={item.secondAnswer ? '16px' : '0px'}>
              {item.firstAnswer}
            </PSmall>
            {item.secondAnswer && (
              <PSmall scale={400} marginBottom="0px">
                {item.secondAnswer}
              </PSmall>
            )}
          </InterUIAccordion>
        ))}
      </CommonQuestionsContent>
    </>
  )
}
