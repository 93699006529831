import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { PageDirections } from 'src/routes/pageDirections'
import { NavbarActions } from 'src/store/ducks/navbar/actions'

import { InterUINavbar } from '@interco/inter-ui-react-lib'

export const Navbar: React.FC = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const title = useSelector(NavbarActions.getTitle)
  const backNavigation = useSelector(NavbarActions.getBackNavigationHandler)

  const isInitialPage = (path: string) => {
    const initialPage = [TypesRoutes.START]

    return initialPage.find((page) => page === path) !== undefined
  }

  const onClickedBack = () => {
    dispatch(NavbarActions.setDirection(PageDirections.BACKWARD))

    if (isInitialPage(location.pathname)) {
      BaseBridge.requestGoBack()
    } else {
      backNavigation()
    }
    setTimeout(() => {
      dispatch(NavbarActions.setDirection(PageDirections.FORWARD))
    }, 550)
  }

  return (
    <>
      <InterUINavbar title={title} onClickedBack={onClickedBack} data-testid="navbar-container" />
    </>
  )
}
