import { SagaWithHistory } from '../utils/providerSaga'

export enum ProposalTypes {
  GET_CHECKOUT_PROPOSAL_REQUEST = `@proposal/GET_CHECKOUT_PROPOSAL_REQUEST`,
  GET_CHECKOUT_PROPOSAL = `@proposal/GET_CHECKOUT_PROPOSAL`,
  SET_CHECKOUT_PROPOSAL = `@proposal/SET_CHECKOUT_PROPOSAL`,
}

export interface ICheckoutProposalRequest extends SagaWithHistory {
  product?: string
  plan: number
  paymentType: string
}

export interface ICheckoutResponse {
  status: string
  cpjCnpj: string
  plan: {
    title: string
    instalmentValue: number
  }
}

export interface ICheckoutProposal {
  name: string
  cpf: string
  cnpj: string
  planType: string
  planValue: number
  paymentMethod: string
  status: string
}

export interface ProposalState {
  readonly checkoutProposal: ICheckoutProposal
}
