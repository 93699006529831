import { AnyAction } from 'redux'
import { errorHandlingSaga, redirectThroughSaga } from 'src/store/ducks//utils/providerSaga'
import { takeLatest } from 'typed-redux-saga'

import { DocumentTypes } from './types'

function* redirectToDocument({ payload }: AnyAction) {
  try {
    redirectThroughSaga(payload)
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'DocumentActions.getDocument')
  }
}

export default function* watchDocuments() {
  yield* takeLatest(DocumentTypes.SET_REDIRECT_TO_DOCUMENT, redirectToDocument)
}
