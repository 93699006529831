/**
 * Formata um numero para moeda Real
 * @param value numero a ser formatado
 * @returns uma string com o valor formatado em Reais
/**
 * Define o scroll no topo da página.
 */
export const scrollToTop = () => {
  window.scrollTo(0, 0)
}

/**
 * Formata um numero para moeda Real
 * @param data numero a ser formatado
 * @returns uma string com o valor formatado em Reais
 */
export const numberFormat = (data: number | string) => {
  try {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })

    const dataFormatted = Number(data)
    return formatter.format(dataFormatted)
  } catch {
    return data.toString()
  }
}
