import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { ICheckoutProposal, ICheckoutProposalRequest, ProposalTypes } from './types'

// TODO mudar nome
const getCheckoutProposalRequest = (data?: ICheckoutProposalRequest) =>
  action(ProposalTypes.GET_CHECKOUT_PROPOSAL_REQUEST, data)

const getCheckoutProposal = (state: RootState) => state.proposal.checkoutProposal

const setCheckoutProposal = (data: ICheckoutProposal) =>
  action(ProposalTypes.SET_CHECKOUT_PROPOSAL, data)

export const ProposalActions = {
  getCheckoutProposalRequest,
  getCheckoutProposal,
  setCheckoutProposal,
}
