import Attention from '@interco/icons/orangeds/MD/attention'
import CardProtection from '@interco/icons/orangeds/MD/card-protection'
import Check from '@interco/icons/orangeds/MD/check-circle'
import MoneyProtection from '@interco/icons/orangeds/MD/money-protection'
import StarFill from '@interco/icons/orangeds/MD/star-fill'
import Help from '@interco/icons/orangeds/MD/help'
import ChevronRight from '@interco/icons/orangeds/MD/chevron-right'
import Card from '@interco/icons/orangeds/MD/card'
import Credit from '@interco/icons/orangeds/MD/credit'
import Canceled from '@interco/icons/orangeds/MD/canceled'
import Home from '@interco/icons/orangeds/MD/home'

export const Icons = {
  CardProtection,
  ChevronRight,
  Credit,
  StarFill,
  MoneyProtection,
  Help,
  Card,
  Attention,
  Check,
  Canceled,
  Home,
}
