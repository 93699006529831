import InterWebviewBridge, {
  interWbAuth,
  interWbNavigate,
  interWbSession,
  IWbAppInfo,
  IWbISafeResponse,
  IWbUserInfo,
  WbPermissions,
  WbSessionActions,
} from '@interco/inter-webview-bridge'

import NewRelicUtils from '../monitoring/NewRelicUtils'
import BridgeMock from './BridgeMock.json'

interface IRequestISafe {
  category: string
  checkType: string
  feature: string
  value: number
}
export class BaseBridge {
  private static backNavigationHandler: (() => void) | null = null

  static isBrowser() {
    const iWb = InterWebviewBridge.getInstance()

    if (process.env.REACT_APP_BRIDGE_DEBUG === 'true') {
      iWb.setDebug(true)
    }

    return iWb.getEnvironment() === 'BROWSER'
  }

  static isIOS() {
    const iWb = InterWebviewBridge.getInstance()

    if (process.env.REACT_APP_BRIDGE_DEBUG === 'true') {
      iWb.setDebug(true)
    }

    return iWb.getEnvironment() === 'IOS'
  }

  static isAndroid() {
    const iWb = InterWebviewBridge.getInstance()

    if (process.env.REACT_APP_BRIDGE_DEBUG === 'true') {
      iWb.setDebug(true)
    }

    return iWb.getEnvironment() === 'ANDROID'
  }

  static openDeepLink(deepLink: string) {
    if (!BaseBridge.isBrowser()) {
      interWbNavigate.openDeepLink(deepLink)
    }
  }

  static async requestISafe(data: IRequestISafe) {
    if (BaseBridge.isBrowser()) {
      return BridgeMock.requestISafe as IWbISafeResponse
    }

    try {
      return await interWbAuth.requestISafe(data.category, data.checkType, data.feature, data.value)
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseBridge.requestISafe',
      })

      return Promise.reject(error)
    }
  }

  static addBackListener(event: () => void) {
    if (!BaseBridge.isBrowser()) {
      const iWb = InterWebviewBridge.getInstance()

      if (BaseBridge.backNavigationHandler) {
        iWb.removeWebviewEventListener(WbSessionActions.GO_BACK, BaseBridge.backNavigationHandler)
      }

      iWb.addWebviewEventListener(WbSessionActions.GO_BACK, event)

      BaseBridge.backNavigationHandler = event
    }
  }

  static requestGoBack() {
    if (!BaseBridge.isBrowser()) {
      interWbNavigate.requestGoBack()
    }
  }

  static async getUserInfo() {
    if (BaseBridge.isBrowser()) {
      return BridgeMock.getUserInfo as IWbUserInfo
    }

    try {
      return await interWbSession.getUserInfo()
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseBridge.getUserInfo',
      })

      return Promise.reject(error)
    }
  }

  static async getAppInfo() {
    if (BaseBridge.isBrowser()) {
      return BridgeMock.getAppInfo as IWbAppInfo
    }

    try {
      return await interWbSession.getAppInfo()
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseBridge.getAppInfo',
      })

      return Promise.reject(error)
    }
  }

  static openLinkOnBrowser(link: string) {
    if (BaseBridge.isBrowser()) {
      return window.open(link, '_blank', 'noopener,noreferrer')
    }

    try {
      return interWbNavigate.openNativeBrowser(link)
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseBridge.openLinkOnBrowser',
      })

      return Promise.reject(error)
    }
  }

  static async requestVideoCallPermissions() {
    let audio = 'N.A.'
    let video = 'N.A.'

    if (!BaseBridge.isBrowser()) {
      try {
        audio = await interWbSession.requestPermissions(WbPermissions.AUDIO)
      } catch (error) {
        NewRelicUtils.noticeError(error as Error, {
          errorCodeRef: 'BaseBridge.requestAudioPermissions',
        })

        audio = 'ERROR'
      }

      try {
        video = await interWbSession.requestPermissions(WbPermissions.CAMERA)
      } catch (error) {
        NewRelicUtils.noticeError(error as Error, {
          errorCodeRef: 'BaseBridge.requestVideoPermissions',
        })

        video = 'ERROR'
      }

      return {
        audio,
        video,
      }
    }

    return {
      audio,
      video,
    }
  }

  static requestAnalytics(name: string, params?: Record<string, string>) {
    if (!BaseBridge.isBrowser()) interWbSession.requestAnalytics(name, params)
  }
}

export default BaseBridge
