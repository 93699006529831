import { Reducer } from 'redux'
import { PageTitles } from 'src/enums/pageTitles'

import { NavbarState, NavbarTypes } from './types'

const INITIAL_STATE: NavbarState = {
  title: PageTitles.MOST_CARD_PROTECTED,
  direction: 'forward',
  backNavigationHandler: () => undefined,
}

const reducer: Reducer<NavbarState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NavbarTypes.SET_TITLE:
      return { ...state, title: action.payload }
    case NavbarTypes.SET_DIRECTION:
      return { ...state, direction: action.payload }
    case NavbarTypes.SET_BACK_NAVIGATION_HANDLER:
      return { ...state, backNavigationHandler: action.payload }
    default:
      return state
  }
}

export default reducer
