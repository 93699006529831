export enum EligibilityTypes {
  GET_ELIGIBILITY = `@user/GET_ELIGIBILITY`,
  SET_ELIGIBILITY = `@user/SET_ELIGIBILITY`,
  GET_ELIGIBILITY_REQUEST = `@user/GET_ELIGIBILITY_REQUEST`,
}

export interface IEligibility {
  elegivel: boolean
  motivoInelegivel: string
}

export interface EligibilityState {
  readonly eligibility: IEligibility
}
