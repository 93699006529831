import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { ErrorActions } from 'src/store/ducks/error/actions'

import { ErrorPage } from './ErrorPage/ErrorPage'

export const ErrorView: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const showError = useSelector(ErrorActions.get)
  const errorDetails = useSelector(ErrorActions.getErrorDetails)

  const goBack = () => BaseBridge.requestGoBack()

  const done = () => {
    dispatch(ErrorActions.hide())

    if (errorDetails.route) {
      history.push(errorDetails.route)
    } else {
      history.goBack()
    }
  }

  const errorView = (
    <ErrorPage
      title={errorDetails.title}
      subTitle={errorDetails.subTitle}
      nameButton={errorDetails.nameButton}
      disabledButton={errorDetails.disabledButton}
      goHome={goBack}
      done={done}
    />
  )

  return <>{showError && errorView}</>
}
