import { Document } from 'src/components'
import { Start, LandingPage, RequestSent } from 'src/pages/index'

import { TypesRoutes as Routes } from './types'

export const mixedRoutes = [
  { route: Routes.START, component: Start },
  { route: Routes.LANDING_PAGE, component: LandingPage },
  { route: Routes.REQUEST_SENT, component: RequestSent },
  { route: Routes.DOCUMENTS, component: Document },
]

export * from './types'
