import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { LoadHome } from 'src/components/Skeleton/LoadHome/LoadHome'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { PathQueryParams } from 'src/routes/pathQueryParams'
import { EligibilityActions } from 'src/store/ducks/eligibility/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { ContainerFullPage } from 'src/styles/commons'

export const Start: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const prefixPathParams = useSelector(PathParamsActions.getPrefixPathParams)

  const handler = PathQueryParams()

  useEffect(() => {
    if (prefixPathParams) {
      const url = `https://${String(prefixPathParams)}.iscpe.uatbi.com.br`
      window.open(url, '_self')
    }
  }, [prefixPathParams])

  useEffect(() => {
    handler()
    dispatch(
      EligibilityActions.getEligibilityRequest({
        history,
        pathname: TypesRoutes.LANDING_PAGE,
      }),
    )
  }, [])

  return (
    <ContainerFullPage height="0px" margin="0" overflowHidden>
      <LoadHome />
    </ContainerFullPage>
  )
}
