import styled, { keyframes } from 'styled-components'

interface StyledProps {
  height?: string
  margin?: string
  marginBottom?: string
  scale?: number
  bold?: boolean
  textAlign?: string
  fontWeight?: string
  overflowHidden?: boolean
  color?: string
}

/**
 * Animações dos ícones
 */
const AnimatedSuccess = keyframes`
 0% {
    opacity: 0;
		transform: scale(0.7);
		box-shadow: 0 0 0 0 rgba(0, 170, 79, 0.7);
	}

	70% {
    opacity: 1;
		transform: scale(1.1);
		box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
	}

	100% {
    opacity: 1;
    transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`

const AnimatedAlert = keyframes`
 0% {
    opacity: 0;
		transform: scale(0.7);
		box-shadow: 0 0 0 0 rgba(255, 199, 0, 0.7);
	}

	70% {
    opacity: 1;
		transform: scale(1.1);
		box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
	}

	100% {
    opacity: 1;
    transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`

/**
 * Animações dos ícones
 */
const AnimatedError = keyframes`
 0% {
    opacity: 0;
		transform: scale(0.7);
		box-shadow: 0 0 0 0 rgba(255, 98, 39, 0.7);
	}

	70% {
    opacity: 1;
		transform: scale(1.1);
		box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
	}

	100% {
    opacity: 1;
    transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`

const AnimatedInfo = keyframes`
 0% {
    opacity: 0;
		transform: scale(0.7);
		box-shadow: 0 0 0 0 rgba(255, 122, 0, .7);
	}

	70% {
    opacity: 1;
		transform: scale(1.1);
		box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
	}

	100% {
    opacity: 1;
    transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`

/**
 * Container a ser utilizado nas páginas que possuem stick footer.
 */
export const ContainerFullPage = styled.div<StyledProps>`
  height: calc(100vh - 56px - ${(props) => props.height || '96px'});
  ${(props) => props.margin && `margin: ${props.margin}`};
  ${(props) => (props.overflowHidden ? `overflow: hidden;` : `overflow: scroll;`)}
`

/**
 * Parágrafo com margin, bold, alinhamento e cor via props.
 */
export const P = styled.p<StyledProps>`
  ${(props) => props.margin && `margin: ${props.margin}`};
  ${(props) => !props.margin && `margin-bottom: ${props.marginBottom || '8px'}`};
  ${(props) => props.scale === 300 && `color: ${props.theme.colors.neutral.grayscale.A300}`};
  ${(props) => props.scale === 400 && `color: ${props.theme.colors.neutral.grayscale.A400}`};
  ${(props) => props.scale === 500 && `color: ${props.theme.colors.neutral.grayscale.A500}`};
  ${(props) => props.color && `color: ${props.color}`};
  ${(props) => props.bold && `font-weight: bold;`}
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
`

/**
 * Parágrafo com font-size 10px.
 */
export const PXSmall = styled(P)`
  font-size: 0.625rem;
  line-height: 0.75rem;
`

/**
 * Parágrafo com font-size 12px.
 */
export const PSmall = styled(P)`
  font-size: 0.75rem;
  line-height: 0.9375rem;
`

/**
 * Parágrafo com font-size 14px.
 */
export const PMedium = styled(P)`
  font-size: 0.875rem;
  line-height: 1.063rem;
`

/**
 * Parágrafo com font-size 16px.
 */
export const PLarge = styled(P)`
  font-size: 1rem;
  line-height: 1.188rem;
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight};`}
`

/**
 * Títulos com margin, alinhamento e cor via props.
 */
export const H1 = styled.h1<StyledProps>`
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
`
export const H2 = styled.h2<StyledProps>`
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
`

export const H3 = styled.h3<StyledProps>`
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  ${(props) => props.scale === 400 && `color: ${props.theme.colors.neutral.grayscale.A400}`};
`

/**
 * Ícones exibidos para as telas de sucesso, erro e alerta
 */
export const Sign = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  margin: 0 auto;
  border-radius: 50%;
  opacity: 0;
`

export const SuccessSign = styled(Sign)`
  animation: ${AnimatedSuccess} 2s 0.3s forwards;
  background-color: ${(props) => `${props.theme.colors.feedback.success.A500}`};
`
export const AlertSign = styled(Sign)`
  animation: ${AnimatedAlert} 2s 0.3s forwards;
  background-color: ${(props) => `${props.theme.colors.feedback.warning.A500}`};
`
export const ErrorSign = styled(Sign)`
  animation: ${AnimatedError} 2s 0.3s forwards;
  background-color: ${(props) => `${props.theme.colors.feedback.error.A500}`};
`

export const InfoSign = styled(Sign)`
  animation: ${AnimatedInfo} 2s 0.3s forwards;
  background-color: ${(props) => `${props.theme.colors.primary.A100}`};
`

/**
 * Botão com layout de link.
 */
export const BtnLink = styled.button.attrs({ type: 'button' })`
  color: ${(props) => props.theme.colors.primary.A500};
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.063rem;
  text-align: left;
`
