import { Reducer } from 'redux'

import { PathParamsState, PathParamsTypes } from './types'

const INITIAL_STATE: PathParamsState = {
  permissionParams: '',
  prefixPathParams: '',
  utmCampaignParams: '',
}

export const reducer: Reducer<PathParamsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PathParamsTypes.SET_PERMISSION_PARAMS:
      return { ...state, permissionParams: action.payload }
    case PathParamsTypes.SET_PREFIX_PATH_PARAMS:
      return { ...state, prefixPathParams: action.payload }
    case PathParamsTypes.SET_UTM_CAMPAIGN_PARAMS:
      return { ...state, utmCampaignParams: action.payload }
    default:
      return state
  }
}

export default reducer
