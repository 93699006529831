import styled from 'styled-components'

export const Group = styled.div<{ margin?: string; gap?: string }>`
  display: flex;
  justify-content: space-between;
  text-align: center;
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.gap && `gap: ${props.gap};`}
`
export const Box = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
`

export const BoxHeader = styled.div`
  padding: 12px 0px;
  background-color: ${(props) => props.theme.colors.primary.A100};
`

export const BoxBody = styled.div`
  padding: 22px 8px;
  background-color: ${(props) => props.theme.colors.base.white};
`

export const ContentRadio = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.primary.A100};
  border-radius: 8px;
  text-align: left;

  &.isActive {
    background-color: ${(props) => props.theme.colors.primary.A500};
  }

  label {
    padding: 8px;
    border: none;

    div {
      padding: 0 3px 0 0;
    }
  }
`

export const ContractTerms = styled.div`
  display: flex;
  align-items: center;
  margin: 2px 0px;

  button {
    display: contents;
    font-size: 0.75rem;
    line-height: 0.9375rem;
  }
`
