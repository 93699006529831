import axios from 'axios'

import BaseBridge from '../config/bridge/BaseBridge'

const { REACT_APP_INTER_ENV, REACT_APP_MOCK_SERVER_BASE_URL, REACT_APP_MOCK_SERVER_API_KEY } =
  process.env

const IS_NOT_RUNNING_PROD =
  REACT_APP_INTER_ENV && REACT_APP_INTER_ENV.toLowerCase() !== 'production'

export class MockService {
  private static _baseUrl = REACT_APP_MOCK_SERVER_BASE_URL || ''

  /**
   * The private key to set in the header 'x-api-key'
   * see @method prepareAxios and @method prepareMock
   */
  private static _apiKey = REACT_APP_MOCK_SERVER_API_KEY || ''

  /**
   * Represent the account of the client
   * and is used to match the correct return of mocked service
   * using the 'x-mock-match-request-headers' and 'x-mock-conta-corrente'
   * see @method prepareAxios and @method prepareMock
   */
  private static _account: string

  /**
   * The flag which determine if the app should consider the mock services
   * see @method prepareAxios and @method prepareMock
   */
  private static _shouldMock: boolean

  /**
   * The headers that determine the correct mock to return via postman mock server
   * using the 'x-mock-match-request-headers' and 'x-mock-conta-corrente'
   * see @method prepareMatchHeadersByUserAccount
   */
  private static _matchHeadersByUserAccount: Record<string, string>

  static get baseUrl(): string {
    return MockService._baseUrl
  }

  static get apiKey(): string {
    return MockService._apiKey
  }

  static get account(): string {
    return MockService._account
  }

  static set account(account: string) {
    MockService._account = account
  }

  static get shouldMock(): boolean {
    return MockService._shouldMock
  }

  static get matchHeadersByUserAccount(): Record<string, string> {
    return MockService._matchHeadersByUserAccount
  }

  static prepareMatchHeadersByUserAccount(): void {
    if (MockService.shouldMock && IS_NOT_RUNNING_PROD && BaseBridge.isBrowser()) {
      MockService._matchHeadersByUserAccount = {
        'x-mock-conta-corrente': MockService.account || '',
        'x-mock-match-request-headers': 'x-mock-conta-corrente',
      }
    }
  }

  /**
   * The method witch configure the mock service to communicate with the mock server.
   * It should be call when was neccessary to setting the application mock strategy,
   * this is the only method that turn shouldMock to true and only have effect if:
   * - @var props.shouldMock is true
   * - @const IS_NOT_RUNNING_PROD is true
   */
  static prepareMock(props: { shouldMock: boolean; apiKey?: string; account?: string }): void {
    if (props.shouldMock && IS_NOT_RUNNING_PROD && BaseBridge.isBrowser()) {
      MockService._shouldMock = props.shouldMock
    }

    /**
     * Even when should not mock (props.mock is not true)
     * the apiKey can be setted to mock the client
     * in staging environment and only for browser
     */
    if (props.apiKey) {
      MockService._apiKey = props.apiKey
    }

    /**
     * Even when should not mock (props.mock is not true)
     * the account can be setted to differ the multiple scenarios
     * in development environment and only for browser
     */
    if (props.account) {
      MockService._account = props.account
    }

    MockService.prepareMatchHeadersByUserAccount()
  }

  /**
   * The method witch configure the axios to communicate with the mock server.
   * It should be call before any http call that should be mocked by axios
   * and only have effect if:
   * - @var shouldMock is true
   * - @const IS_NOT_RUNNING_PROD is true
   */
  static prepareAxios(): void {
    if (MockService.shouldMock && IS_NOT_RUNNING_PROD) {
      axios.defaults.headers['x-api-key'] = MockService.apiKey || ''
      axios.defaults.baseURL = MockService.baseUrl
    }
  }
}
