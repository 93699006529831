import React from 'react'

import { Button } from 'src/components/Button/Button'
import { AlertSign, H1, PMedium } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { BtnBack, Container, Content, ErrorContainer } from './ErrorPage.styles'

interface IErrorPage {
  title: string | JSX.Element
  subTitle: string | JSX.Element
  nameButton?: string
  disabledButton?: boolean
  goHome: () => void
  done: () => void
}

export const ErrorPage: React.FC<IErrorPage> = ({
  title,
  subTitle,
  nameButton,
  disabledButton,
  goHome,
  done,
}) => {
  const theme = useTheme()

  const errorIcon = <Icons.Attention width={32} height={32} color={theme.colors.base.white} />
  const homeIcon = <Icons.Home width={24} height={24} color={theme.colors.primary.A500} />

  const button = (
    <Button className="foot-button" data-testid="done-button" onClick={done}>
      {nameButton || 'Entendi'}
    </Button>
  )

  return (
    <ErrorContainer>
      <Container>
        <BtnBack
          type="button"
          onClick={goHome}
          aria-label="Botão voltar erro"
          data-testid="home-button"
        >
          {homeIcon}
        </BtnBack>
        <Content>
          <AlertSign>{errorIcon}</AlertSign>
          <H1 margin="24px 0 8px">{title}</H1>
          <PMedium scale={400}>{subTitle}</PMedium>
        </Content>
        {!disabledButton && button}
      </Container>
    </ErrorContainer>
  )
}
