import { Reducer } from 'redux'

import { ICheckoutProposal, ProposalState, ProposalTypes } from './types'

const INITIAL_STATE: ProposalState = {
  checkoutProposal: {} as ICheckoutProposal,
}

const reducer: Reducer<ProposalState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProposalTypes.SET_CHECKOUT_PROPOSAL:
      return { ...state, checkoutProposal: action.payload }

    default:
      return state
  }
}

export default reducer
