import { Reducer } from 'redux'

import { IEligibility, EligibilityState, EligibilityTypes } from './types'

const INITIAL_STATE: EligibilityState = {
  eligibility: {} as IEligibility,
}

const reducer: Reducer<EligibilityState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EligibilityTypes.SET_ELIGIBILITY:
      return { ...state, eligibility: action.payload }

    default:
      return state
  }
}

export default reducer
