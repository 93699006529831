import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BannerImg from 'src/assets/img/banner.png'
import { Button } from 'src/components'
import { CommonQuestions } from 'src/components/CommonQuestions/CommonQuestions'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { DocumentActions } from 'src/store/ducks/document/action'
import { EligibilityActions } from 'src/store/ducks/eligibility/actions'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { ProposalActions } from 'src/store/ducks/proposal/actions'
import { BtnLink, H1, H2, PLarge, PMedium, PSmall, PXSmall } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import {
  InterUIBox,
  InterUICheckBox,
  InterUIContainer,
  InterUIListDescription,
  InterUIRadio,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

import { HireButtonLabelEnum } from './enums'
import { Box, BoxBody, BoxHeader, ContentRadio, ContractTerms, Group } from './LandingPage.styles'
import { Document, IListBenefits, IListPlans, Payment, Plans } from './types'

export const LandingPage: React.FC = () => {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  const permission = useSelector(PathParamsActions.getPermissionParams)
  const eligibility = useSelector(EligibilityActions.getEligibility)
  const isLoading = useSelector(LoadingActions.get)
  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)

  const [selectedPlan, setSelectedPlan] = useState<Plans>(80)
  const [selectedPayment, setSelectedPayment] = useState<Payment>('CREDIT')
  const [acceptTerms, setAcceptTerms] = useState(false)

  const iconCardProtection = (
    <Icons.CardProtection height={24} width={24} color={theme.colors.primary.A500} />
  )
  const iconStarFill = <Icons.StarFill height={24} width={24} color={theme.colors.primary.A500} />
  const iconMoneyProtection = (
    <Icons.MoneyProtection height={24} width={24} color={theme.colors.primary.A500} />
  )
  const iconHelp = <Icons.Help height={24} width={24} color={theme.colors.base.white} />
  const iconChevronRight = (
    <Icons.ChevronRight height={24} width={24} color={theme.colors.base.white} />
  )
  const iconCard = (
    <Icons.Card
      height={16}
      width={16}
      color={theme.colors.neutral.grayscale.A500}
      style={{ marginBottom: '8px' }}
    />
  )
  const iconGoldCinza = (
    <Icons.Credit
      height={16}
      width={16}
      color={theme.colors.neutral.grayscale.A500}
      style={{ marginBottom: '8px' }}
    />
  )

  const listBenefits: IListBenefits[] = [
    {
      id: 0,
      icon: iconCardProtection,
      description: 'Coberturas para perda, roubo ou furto do cartão',
    },
    {
      id: 1,
      icon: iconMoneyProtection,
      description: 'Coberturas para compras ou saques sob ameaça',
    },
    {
      id: 2,
      icon: iconStarFill,
      description: 'Participação em sorteios mensais de R$5.000,00',
    },
  ]

  const listPlans: IListPlans[] = [
    {
      planId: 80,
      plan: 'Standard',
      planValue: 'R$ 2,90',
      coverageAmount: 'R$ 4.000',
    },
    {
      planId: 81,
      plan: 'Plus',
      planValue: 'R$ 4,90',
      coverageAmount: 'R$ 7.500',
    },
    {
      planId: 82,
      plan: 'Premium',
      planValue: 'R$ 7,30',
      coverageAmount: 'R$ 12.000',
    },
  ]

  useBasePage({
    navbarTitle: PageTitles.MOST_CARD_PROTECTED,
    backNavigationHandler: BaseBridge.requestGoBack,
  })

  useEffect(() => {
    BaseBridge.requestAnalytics('C_CARTAOPROT_HOME_DC_ACESSO', {
      ref_figma: '1',
      ref_type: 'tela',
      screen: 'LP C+P PJ',
      content_action: 'Dado carregado',
      action_id: 'Acessou o C+P',
      utm_campaign: utmCampaign || 'SEM_CAMPANHA',
    })

    if (!permission) {
      BaseBridge.requestAnalytics('CARTAOPROT_FLUXO_PERMISSION', {
        ref_figma: '5',
        ref_type: 'tela',
        screen: 'LP C+P PJ',
        content_action: 'Dado Carregado',
        action_id: 'Contratação exclusiva ao titular da conta',
        utm_campaign: utmCampaign || 'SEM_CAMPANHA',
      })
    }

    if (!eligibility?.elegivel) {
      BaseBridge.requestAnalytics('CARTAOPROT_FLUXO_ELIGIBILITY', {
        ref_figma: '5',
        ref_type: 'tela',
        screen: 'LP C+P PJ',
        content_action: 'Dado Carregado',
        action_id: eligibility?.motivoInelegivel?.includes('ATINGIDO')
          ? HireButtonLabelEnum.ALREADY_HIRED
          : HireButtonLabelEnum.NOT_AVAILABLE,
        utm_campaign: utmCampaign || 'SEM_CAMPANHA',
      })
    }
  }, [])

  const renderBenefits = (
    <>
      {listBenefits.map((item) => {
        const key = `benefit-${item.id}`
        const lastItem = listBenefits[listBenefits.length - 1]
        const firstItem = listBenefits[0]

        return (
          <InterUIListDescription
            key={key}
            iconLeft={item.icon}
            alignAllItems="center"
            style={{
              padding: '16px 12px',
              backgroundColor: `${item.id === lastItem.id && theme.colors.primary.A100}`,
              borderRadius: `${item.id === lastItem.id && '8px'}`,
            }}
            margin={`${item.id === firstItem.id ? '0' : '0 0 8px'}`}
          >
            <PMedium marginBottom="0" bold>
              {item.description}
            </PMedium>
          </InterUIListDescription>
        )
      })}
    </>
  )

  const renderPlans = (
    <>
      {listPlans.map((item) => (
        <Box key={item.planId}>
          <BoxHeader>
            <PXSmall marginBottom="0px" bold color={theme.colors.primary.A500}>
              {item.plan}
            </PXSmall>
            <PSmall marginBottom="0px" bold>
              {item.planValue}
            </PSmall>
          </BoxHeader>
          <BoxBody>
            <PSmall marginBottom="0px" bold color={theme.colors.base.black}>
              Coberturas de
            </PSmall>
            <PMedium marginBottom="0px" bold color={theme.colors.primary.A500}>
              {item.coverageAmount}
            </PMedium>
          </BoxBody>
        </Box>
      ))}
    </>
  )

  const renderSelectPlan = (
    <>
      {listPlans.map((item) => (
        <ContentRadio
          key={item.planId}
          className={selectedPlan === item.planId ? 'isActive' : undefined}
        >
          <InterUIRadio
            variant="choose-item"
            margin="0px"
            padding="8px"
            radioPosition="right"
            color={theme.colors.base.white}
            value={item.planId}
            checked={selectedPlan === item.planId}
            onChange={(e) => setSelectedPlan(Number(e.target.value) as Plans)}
            disabled={isLoading}
          >
            <PXSmall
              marginBottom="2px"
              color={
                selectedPlan === item.planId ? theme.colors.base.white : theme.colors.primary.A500
              }
              bold
            >
              {item.plan}
            </PXSmall>
            <PSmall
              marginBottom="0px"
              color={
                selectedPlan === item.planId
                  ? theme.colors.base.white
                  : theme.colors.neutral.grayscale.A500
              }
              bold
            >
              {item.planValue}
            </PSmall>
          </InterUIRadio>
        </ContentRadio>
      ))}
    </>
  )

  const scrollToCTA = () => {
    const anchorCTA = document.getElementById('know-more-button')
    if (anchorCTA instanceof HTMLElement) {
      anchorCTA.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }

    BaseBridge.requestAnalytics('CARTAOPROT_FLUXO_BUTTON_CTA', {
      ref_figma: '2',
      ref_type: 'botão',
      screen: 'LP C+P PJ',
      content_action: 'Clique',
      action_id: 'Contratar',
      utm_campaign: utmCampaign || 'SEM_CAMPANHA',
    })
  }

  const defineButtonLabel = () => {
    if (!permission) {
      return HireButtonLabelEnum.MASTER_EXCLUSIVE
    }

    if (eligibility?.motivoInelegivel?.includes('ATINGIDO')) {
      return HireButtonLabelEnum.ALREADY_HIRED
    }

    return HireButtonLabelEnum.NOT_AVAILABLE
  }

  const handleClickDocument = (document: Document) => {
    dispatch(
      DocumentActions.setRedirectToDocument({
        history,
        pathname: TypesRoutes.DOCUMENTS,
        type: document,
      }),
    )

    BaseBridge.requestAnalytics('CARTAOPROT_FLUXO_BUTTON_KNOW_MORE', {
      ref_figma: '3',
      ref_type: 'botão',
      screen: 'LP C+P PJ',
      content_action: 'Clique',
      action_id: 'Rede Credenciada',
      utm_campaign: utmCampaign || 'SEM_CAMPANHA',
    })
  }

  const handleClickSelectedPayment = (paymentMethod: Payment) => {
    !isLoading && setSelectedPayment(paymentMethod)
  }

  const handleClickHireNow = () => {
    dispatch(
      ProposalActions.getCheckoutProposalRequest({
        history,
        pathname: TypesRoutes.REQUEST_SENT,
        plan: selectedPlan,
        paymentType: selectedPayment,
      }),
    )

    const planValue = listPlans.find((selected) => selected.planId === selectedPlan)?.planValue
    const plan = listPlans.find((selected) => selected.planId === selectedPlan)?.plan

    BaseBridge.requestAnalytics('CARTAOPROT_FLUXO_BUTTON_HIRE_NOW', {
      ref_figma: '4',
      ref_type: 'botão',
      screen: 'LP C+P PJ',
      content_action: 'Clique',
      action_id: 'Contratar agora',
      value: planValue as string,
      plans: plan as string,
      utm_campaign: utmCampaign || 'SEM_CAMPANHA',
    })
  }

  return (
    <InterUIContainer margin="0">
      <img src={BannerImg} style={{ width: '100%' }} alt="Banner landing page" />

      <InterUIContainer margin="0 24px 32px">
        <H1 margin="40px 0 16px">Cartão Mais Protegido Empresarial</H1>
        <PMedium marginBottom="16px">
          Com o seguro para cartão empresarial, você protege ainda mais o cartão da sua empresa, de
          forma prática e sem burocracia.
        </PMedium>
        {permission && eligibility?.elegivel ? (
          <Button data-testid="hire-button" onClick={scrollToCTA}>
            {HireButtonLabelEnum.HIRE}
          </Button>
        ) : (
          <Button data-testid="hire-button" disabled>
            <PMedium marginBottom="0" color={theme.colors.neutral.grayscale.A500}>
              {defineButtonLabel()}
            </PMedium>
          </Button>
        )}
      </InterUIContainer>

      <InterUISeparator />

      <InterUIContainer margin="32px 24px">
        <H2 marginBottom="24px">O que o seguro Cartão Mais Protegido Empresarial oferece?</H2>
        {renderBenefits}
      </InterUIContainer>

      <InterUIContainer margin="0" style={{ backgroundColor: theme.colors.primary.A500 }}>
        <InterUIContainer margin="0" style={{ padding: '48px 24px' }}>
          <H1 marginBottom="8px" style={{ color: theme.colors.base.white }}>
            Um plano ideal para o seu negócio.
          </H1>
          <PMedium marginBottom="28px" color={theme.colors.base.white}>
            Para perda, roubo ou furto do cartão e saque ou compra sob coação
          </PMedium>
          <Group gap="18px">{renderPlans}</Group>
          <InterUIListDescription
            margin="28px 0 0"
            withBorder
            iconLeft={iconHelp}
            iconRight={iconChevronRight}
            small
            onClick={() => handleClickDocument('MANUAL')}
            data-testid="product-manual"
            borderColor={theme.colors.base.white}
          >
            <PMedium
              data-testid="know-more-button"
              id="know-more-button"
              marginBottom="0px"
              bold
              color={theme.colors.base.white}
            >
              Saiba mais
            </PMedium>
          </InterUIListDescription>
        </InterUIContainer>
      </InterUIContainer>

      {permission && eligibility?.elegivel && (
        <>
          <InterUIContainer margin="24px 24px 32px">
            <H1 marginBottom="8px">Contrate agora!</H1>
            <PLarge marginBottom="16px" scale={400} bold>
              É simples, prático, sem burocracia e exclusivo para cliente Inter PJ!
            </PLarge>
            <PMedium marginBottom="12px" scale={400}>
              Selecione seu plano:
            </PMedium>
            <Group margin="0 0 16px" gap="12px">
              {renderSelectPlan}
            </Group>
            <PMedium marginBottom="12px" scale={400}>
              Forma de pagamento:
            </PMedium>
            <Group gap="8px">
              <InterUIBox
                onClick={() => handleClickSelectedPayment('CREDIT')}
                actived={selectedPayment === 'CREDIT'}
                data-testid="test-selectedPayment-credit"
                direction="column"
                align="flex-start"
                style={{ width: '100%' }}
              >
                {iconGoldCinza}
                <PXSmall marginBottom="0px" bold>
                  Crédito Inter
                </PXSmall>
              </InterUIBox>
              <InterUIBox
                onClick={() => handleClickSelectedPayment('DEBIT')}
                actived={selectedPayment === 'DEBIT'}
                data-testid="test-selectedPayment-debit"
                direction="column"
                align="flex-start"
                style={{ width: '100%' }}
              >
                {iconCard}
                <PXSmall marginBottom="0px" bold>
                  Débito Inter
                </PXSmall>
              </InterUIBox>
            </Group>
            <ContractTerms>
              <PSmall marginBottom="0">
                Declaro que li e estou de acordo com os{' '}
                <BtnLink
                  data-testid="terms-conditions"
                  onClick={() => handleClickDocument('TERMS')}
                >
                  Termos e Condições
                </BtnLink>{' '}
                do Cartão Mais Protegido.
              </PSmall>
              <InterUICheckBox
                value=""
                name="no-number"
                id="no-number"
                onChange={() => setAcceptTerms(!acceptTerms)}
                checked={acceptTerms}
                disabled={isLoading}
              />
            </ContractTerms>
            <Button
              disabled={!acceptTerms || isLoading}
              data-testid="test-hireNow-button"
              id="cta-button"
              onClick={() => handleClickHireNow()}
            >
              Contratar agora
            </Button>
          </InterUIContainer>
          <InterUISeparator />
        </>
      )}
      <InterUIContainer margin="32px 24px 40px">
        <CommonQuestions />
      </InterUIContainer>
    </InterUIContainer>
  )
}
