import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { SagaWithHistory } from '../utils/providerSaga'
import { IEligibility, EligibilityTypes } from './types'

const getEligibility = (state: RootState) => state.eligibility.eligibility

const setEligibility = (data: IEligibility) => action(EligibilityTypes.SET_ELIGIBILITY, data)

const getEligibilityRequest = (data?: SagaWithHistory) =>
  action(EligibilityTypes.GET_ELIGIBILITY_REQUEST, data)

export const EligibilityActions = {
  getEligibility,
  setEligibility,
  getEligibilityRequest,
}
