export enum PathParamsTypes {
  GET_PERMISSION_PARAMS = `@pathparams/GET_PERMISSION`,
  SET_PERMISSION_PARAMS = `@pathparams/SET_PERMISSION`,
  GET_PREFIX_PATH_PARAMS = `@pathparams/GET_PREFIX_PARAMS`,
  SET_PREFIX_PATH_PARAMS = `@pathparams/SET_PREFIX_PARAMS`,
  GET_UTM_CAMPAIGN_PARAMS = `@pathparams/GET_UTM_CAMPAIGN_PARAMS`,
  SET_UTM_CAMPAIGN_PARAMS = `@pathparams/SET_UTM_CAMPAIGN_PARAMS`,
}

export interface PathParamsState {
  readonly permissionParams: string
  readonly prefixPathParams: string
  readonly utmCampaignParams: string
}
