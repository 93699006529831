import styled from 'styled-components'

export const SkeletonBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.neutral.grayscale.A100};
`

export const SkeletonGrid = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`

export const SkeletonLine = styled.div`
  flex-direction: column;
  margin-bottom: 32px;
`

export const SkeletonContent = styled.div`
  flex: 1;

  img {
    width: 100%;
    height: auto;
  }
`
