import BaseService from 'src/config/bridge/BaseService'
import { IEligibility } from 'src/store/ducks/eligibility/types'

import { MockService } from './mockService'

export default class EligibilityService {
  static getEligibility(account: string) {
    const headers = {
      contaCorrente: account,
      ...MockService.matchHeadersByUserAccount,
    }

    return BaseService.doExecute<IEligibility>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_PROTECTED_CARD_V1}/elegibility/cartao-protegido/pj`,
      headers,
    })
  }
}
