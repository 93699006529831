import BaseService from 'src/config/bridge/BaseService'
import { ICheckoutProposalRequest, ICheckoutResponse } from 'src/store/ducks/proposal/types'

import { MockService } from './mockService'

export default class ProposalService {
  static async checkoutProposal(payload: ICheckoutProposalRequest, account: string) {
    const data = payload as unknown as Record<string, string>

    const headers = {
      contaCorrente: account,
      ...MockService.matchHeadersByUserAccount,
    }

    return BaseService.doExecute<ICheckoutResponse>({
      method: 'POST',
      endpoint: `${process.env.REACT_APP_API_PROTECTED_CARD_V1}/checkout/pj`,
      data,
      headers,
    })
  }
}
