import { AnyAction } from 'redux'
import BaseBridge from 'src/config/bridge/BaseBridge'
import EligibilityService from 'src/services/EligibilityService'
import { EligibilityActions } from 'src/store/ducks/eligibility/actions'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { errorHandlingSaga, redirectThroughSaga } from 'src/store/ducks/utils/providerSaga'
import { call, put, takeLatest } from 'typed-redux-saga'

import { EligibilityTypes } from './types'

function* getEligibility({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const userInfoBridge = yield* call(BaseBridge.getUserInfo)

    const eligibilityResponse = yield* call(
      EligibilityService.getEligibility,
      userInfoBridge.account,
    )

    yield* put(EligibilityActions.setEligibility(eligibilityResponse.object))

    yield* put(LoadingActions.hide())

    if (payload) {
      redirectThroughSaga(payload)
    }
  } catch (error) {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'No momento, a aplicação está indisponível. Por favor, tente novamente em alguns minutos.',
      disabledButton: true,
    }

    yield* errorHandlingSaga(error as Error, 'EligibilityActions.getEligibility', errorDetails)
  }
}

export default function* watchEligibility() {
  yield* takeLatest(EligibilityTypes.GET_ELIGIBILITY_REQUEST, getEligibility)
}
