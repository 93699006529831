import { AnyAction } from 'redux'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import ProposalService from 'src/services/CheckoutService'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { errorHandlingSaga, redirectThroughSaga } from 'src/store/ducks/utils/providerSaga'
import { call, put, takeLatest } from 'typed-redux-saga'

import { ProposalActions } from './actions'
import {
  ICheckoutProposal,
  ICheckoutProposalRequest,
  ICheckoutResponse,
  ProposalTypes,
} from './types'

function* checkoutProposal({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const userInfoBridge = yield* call(BaseBridge.getUserInfo)

    const checkoutProposalRequest: ICheckoutProposalRequest = {
      product: 'CARTAO_PROTEGIDO',
      plan: payload.plan,
      paymentType: payload.paymentType,
    }

    const checkoutProposalResponse = yield* call(
      ProposalService.checkoutProposal,
      checkoutProposalRequest,
      userInfoBridge.account,
    )

    const data = checkoutProposalResponse.object as ICheckoutResponse

    const checkout: ICheckoutProposal = {
      name: userInfoBridge.name,
      cpf: userInfoBridge.cpf,
      cnpj: data.cpjCnpj,
      planType: data.plan.title,
      planValue: data.plan.instalmentValue,
      paymentMethod: payload.paymentType,
      status: data.status,
    }

    yield* put(ProposalActions.setCheckoutProposal(checkout))

    if (payload) {
      redirectThroughSaga(payload)
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'No momento, a aplicação está indisponível. Por favor, tente novamente em alguns minutos.',
      route: TypesRoutes.START,
    }
    yield* errorHandlingSaga(error as Error, 'ProposalActionsSaga.checkoutProposal', errorDetails)
  }
}

export default function* watchProposal() {
  yield* takeLatest(ProposalTypes.GET_CHECKOUT_PROPOSAL_REQUEST, checkoutProposal)
}
