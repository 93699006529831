import React from 'react'

import { InterUIContainer, InterUISeparator, InterUISkeleton } from '@interco/inter-ui-react-lib'

import { SkeletonContent, SkeletonGrid } from '../Skeleton.styles'

export const LoadHome: React.FC = () => (
  <SkeletonContent data-testid="loading-home">
    <InterUIContainer margin="0">
      <SkeletonContent>
        <InterUISkeleton height="215px" marginBottom="0" />
      </SkeletonContent>

      <InterUIContainer margin="52px 24px 32px">
        <SkeletonContent>
          <InterUISkeleton height="60px" marginBottom="16px" />
          <InterUISkeleton height="50px" marginBottom="16px" />
          <InterUISkeleton height="48px" marginBottom="0" />
        </SkeletonContent>
      </InterUIContainer>

      <InterUISeparator height="8px" margin="32px 0" />

      <InterUIContainer margin="24px">
        <SkeletonContent>
          <InterUISkeleton height="72px" marginBottom="44px" />

          <SkeletonGrid>
            <InterUISkeleton width="24px" height="24px" marginBottom="0" />
            <InterUISkeleton height="32px" marginBottom="0" style={{ flex: 1 }} />
          </SkeletonGrid>

          <SkeletonGrid>
            <InterUISkeleton width="24px" height="24px" marginBottom="0" />
            <InterUISkeleton height="32px" marginBottom="0" style={{ flex: 1 }} />
          </SkeletonGrid>

          <SkeletonGrid>
            <InterUISkeleton width="24px" height="24px" marginBottom="0" />
            <InterUISkeleton height="32px" marginBottom="0" style={{ flex: 1 }} />
          </SkeletonGrid>
        </SkeletonContent>
      </InterUIContainer>
    </InterUIContainer>
  </SkeletonContent>
)
